import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";

const AdminSettlements = () => {
  const [usersettlementsi, setUserSettlements] = useState([]);

  useEffect(() => {
    fetchadminSettlements();
  }, []);

  const fetchadminSettlements = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/inyumbaadmin",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUserSettlements(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching User Settlements:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDelete = (settlementId) => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this settlement?")) {
      axios
        .delete(
          `https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/deleteinyumbasettlement/${settlementId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Settlement deleted successfully");
          // Optionally, refresh the list after deletion
          setUserSettlements((prevSettlements) =>
            prevSettlements.filter(
              (settlement) => settlement.settlement_id !== settlementId
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting settlement:", error);
          toast.error("Failed to delete the settlement");
        });
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">
          Admin Inyumba Settlements
        </h1>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Settlement Id</th>
              <th className="px-4 py-2">Total Amount Paid</th>
              <th className="px-4 py-2">Transaction cost</th>
              <th className="px-4 py-2">Our Reference</th>
              <th className="px-4 py-2">Transaction Reference</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">OTP</th>
              <th className="px-4 py-2">Attempts</th>
              <th className="px-4 py-2">Created On</th>
            </tr>
          </thead>
          <tbody>
            {usersettlementsi.map((usersettlementsi) => (
              <tr key={usersettlementsi.settlement_id}>
                <td className="border px-4 py-2">
                  {usersettlementsi.settlement_id}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.total_amount_paid}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.transactioncost}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.reference}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.paymentreference}
                </td>
                <td className="border px-4 py-2">{usersettlementsi.status}</td>
                <td className="border px-4 py-2">{usersettlementsi.otp}</td>
                <td className="border px-4 py-2">
                  {usersettlementsi.attempts}
                </td>
                <td className="border px-4 py-2">
                  {new Date(usersettlementsi.created_at).toLocaleString()}
                </td>
                {/* Add the delete button here */}
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleDelete(usersettlementsi.settlement_id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AuthGuard>
  );
};

export default AdminSettlements;
