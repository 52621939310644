import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const Photo = () => {
  const [rentalId, setRentalId] = useState("");
  const [photos, setPhotos] = useState([]);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
  }, []);

  const handleInputChange = (e) => {
    setRentalId(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchPhotos();
    }
  };

  const fetchPhotos = () => {
    if (!rentalId) {
      toast.error("Please enter a rental ID.");
      return;
    }

    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/photos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPhotos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching photos:", error);
        toast.error("Error fetching photos.");
      });
  };

  const handleAddPhoto = () => {
    setShowAddPhotoModal(true);
  };

  const closeModal = () => {
    setShowAddPhotoModal(false);
  };

  const handlePhotoFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
  };

  const addPhoto = () => {
    if (!photoFile) {
      toast.error("Please select a photo to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", photoFile);

    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/images/images/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        // Update photos state with the response URL
        const newPhoto = { photo_url: response.data.response };
        setPhotos([...photos, newPhoto]);
        closeModal();
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error uploading photo:", error);
      });
  };

  const handleDeletePhoto = (photoId) => {
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/photos/delete/${photoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        // Remove the deleted photo from photos state
        setPhotos(photos.filter((photo) => photo.photo_id !== photoId));
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error deleting photo:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Rental Photos</h1>

        {/* Input field for rental ID */}
        <div className="mb-4 flex items-center justify-center">
          <input
            type="text"
            value={rentalId}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder="Enter Rental ID and press Enter..."
            className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
          />
          {/* Button for fetching photos */}
          <button
            onClick={fetchPhotos}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Fetch Photos
          </button>
        </div>

        {/* Display photos */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Photos</h2>
          <div className="flex flex-wrap">
            {photos.map((photo, index) => (
              <div key={index} className="w-1/4 p-4">
                <img
                  src={photo.photo_url}
                  alt={`rental photo ${index}`}
                  className="w-full h-auto object-cover cursor-pointer"
                />
                {/* Delete button */}
                <button
                  onClick={() => handleDeletePhoto(photo.photo_id)}
                  className="block mt-2 text-red-500 font-semibold"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default Photo;
