import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";

const MoneyInPage = () => {
  const [totalAgencyViewingFee, setTotalAgencyViewingFee] = useState(0);
  const [totalLeavingFee, setTotalLeavingFee] = useState(0);
  const [totalServiceFee, setTotalServiceFee] = useState(0);
  const [totalAllFees, setTotalAllFees] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("token");

    axios
      .all([
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/money/TotalAgencyViewingFee",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/money/TotalLeavingFee",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/money/TotalServiceFee",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get("https://backend.inyumba.co.ke/iNyumba/money/TotalAllFees", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ])
      .then(
        axios.spread(
          (
            totalAgencyViewingFeeRes,
            totalLeavingFeeRes,
            totalServiceFeeRes,
            totalAllFeesRes
          ) => {
            setTotalAgencyViewingFee(totalAgencyViewingFeeRes.data.response);
            setTotalLeavingFee(totalLeavingFeeRes.data.response);
            setTotalServiceFee(totalServiceFeeRes.data.response);
            setTotalAllFees(totalAllFeesRes.data.response);
          }
        )
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="bg-gray-100 rounded-lg p-8 shadow-lg">
          <h1 className="text-3xl font-semibold mb-4">Money In</h1>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">
                Total Agency Viewing Fee
              </h2>
              <p className="text-2xl font-bold">Ksh {totalAgencyViewingFee}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Total Leaving Fee</h2>
              <p className="text-2xl font-bold">Ksh {totalLeavingFee}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Total Service Fee</h2>
              <p className="text-2xl font-bold">Ksh {totalServiceFee}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Total All Fees</h2>
              <p className="text-2xl font-bold">Ksh {totalAllFees}</p>
            </div>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default MoneyInPage;
