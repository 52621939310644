import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader

const TransactionsViewPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const fetchTransactions = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/alltransactionsview/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data.response); // Assuming the response structure
        setIsLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        toast.error(
          error.response?.data?.response || "Error fetching transactions"
        );
        setIsLoading(false); // Set loading state to false
      });
  };

  const handleDownloadReport = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/reportsdownloadtransactions/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions_report_${propertyId}.csv`); // Customize the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        toast.error("Failed to download report");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Transactions View</h1>
        <div className="flex mb-4">
          <input
            type="text"
            value={propertyId}
            onChange={(e) => setPropertyId(e.target.value)}
            placeholder="Enter Property ID"
            className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
          />
          <button
            onClick={fetchTransactions}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
          >
            Search
          </button>
          {/* Download Button */}
          <button
            onClick={handleDownloadReport}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2"
            disabled={!propertyId} // Disable if propertyId is not entered
          >
            Download Report
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center">
            <Oval
              height={40}
              width={40}
              color="blue"
              ariaLabel="loading-indicator"
            />
          </div>
        ) : (
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Date of Transaction</th>
                <th className="px-4 py-2">Transaction ID</th>
                <th className="px-4 py-2">Property ID</th>
                <th className="px-4 py-2">Channel</th>
                <th className="px-4 py-2">Transaction Amount</th>
                <th className="px-4 py-2">Running Balance</th>
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 ? (
                transactions.map((transaction) => (
                  <tr key={transaction.transaction_id}>
                    <td className="border px-4 py-2">
                      {new Date(transaction.created_at).toLocaleDateString()}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.transaction_id}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.propertyid}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.reference}
                    </td>
                    <td className="border px-4 py-2">{transaction.amount}</td>
                    <td className="border px-4 py-2">
                      {transaction.running_balance}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="border px-4 py-2 text-center">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </AuthGuard>
  );
};

export default TransactionsViewPage;
