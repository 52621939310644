import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const LandlordSettlements = () => {
  const [landlordSettlements, setLandlordSettlements] = useState([]);
  const [propertyId, setPropertyId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchLandlord();
  }, []);

  const fetchLandlord = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/landlord",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLandlordSettlements(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching Landlord Settlements:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownload = (endpoint, fileType) => {
    const token = localStorage.getItem("token");
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `landlordsettlementreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.log(error);
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownloadWithPropertyId = (fileType) => {
    const token = localStorage.getItem("token");

    if (!propertyId) {
      toast.error("Please enter a valid Property ID");
      return;
    }

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/landlordsettlement/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType },
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `landlordsettlementreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.log(error);
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };


  const handleDelete = (settlementId) => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this settlement?")) {
      axios
        .delete(
          `https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/deletelandlordsettlement/${settlementId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Settlement deleted successfully");
          // Optionally, refresh the list after deletion
          setLandlordSettlements((prevSettlements) =>
            prevSettlements.filter(
              (settlement) => settlement.settlement_id !== settlementId
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting settlement:", error);
          toast.error("Failed to delete the settlement");
        });
    }
  };


  return (
    <AuthGuard>
      {/* Download buttons */}
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Landlord Settlements</h1>

        {/* Input box and download buttons in a horizontal flexbox */}
        {/* Flex container to separate original and new buttons */}
        <div className="flex justify-between items-center w-full max-w-4xl mb-4">
          {/* Original download buttons on the left */}
          <div className="flex space-x-2">
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/landlordsettlementme",
                  "csv"
                )
              }
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              CSV
            </button>
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/landlordsettlementme",
                  "pdf"
                )
              }
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              PDF
            </button>
          </div>

          {/* Input box and new download buttons on the right */}
          <div className="flex items-center space-x-4">
            <div className="flex-grow">
              <label
                htmlFor="propertyId"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Property ID
              </label>
              <input
                type="text"
                id="propertyId"
                value={propertyId}
                onChange={(e) => setPropertyId(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => handleDownloadWithPropertyId("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                CSV with Property ID
              </button>
              <button
                onClick={() => handleDownloadWithPropertyId("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                PDF with Property ID
              </button>
            </div>
          </div>
        </div>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Settlement Id</th>
            <th className="px-4 py-2">User ID</th>
            <th className="px-4 py-2">Property ID</th>
            <th className="px-4 py-2">Total Amount Paid</th>
            <th className="px-4 py-2">TransactionCost</th>
            <th className="px-4 py-2">Our Reference</th>
            <th className="px-4 py-2">Transaction Reference</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">OTP</th>
            <th className="px-4 py-2">Attempts</th>
            <th className="px-4 py-2">Created On</th>
          </tr>
        </thead>
        <tbody>
          {landlordSettlements.map((landlordSettlement) => (
            <tr key={landlordSettlement.settlement_id}>
              <td className="border px-4 py-2">
                {landlordSettlement.settlement_id}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.owner_id}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.property_id}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.total_amount_paid}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.transactioncost}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.reference}
              </td>
              <td className="border px-4 py-2">
                {landlordSettlement.paymentreference}
              </td>
              <td className="border px-4 py-2">{landlordSettlement.status}</td>
              <td className="border px-4 py-2">{landlordSettlement.otp}</td>
              <td className="border px-4 py-2">
                {landlordSettlement.attempts}
              </td>
              <td className="border px-4 py-2">
                {new Date(landlordSettlement.created_at).toLocaleString()}
              </td>
              {/* Add the delete button here */}
              <td className="border px-4 py-2">
                <button
                  onClick={() => handleDelete(landlordSettlement.settlement_id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AuthGuard>
  );
};

export default LandlordSettlements;
