import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateUserBalance = () => {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editBalanceId, setEditBalanceId] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    fetchUserBalances();
  }, []);

  const fetchUserBalances = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/balance/viewuserbalances",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ); // Debugging: check the response
      setBalances(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch balances");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (balance) => {
    setEditBalanceId(balance.user_id); // Store the balance ID for editing
    setBalanceAmount(balance.balance_amount); // Set the current balance amount
    setUserId(balance.user_id);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/balance/updateuserbalances/${editBalanceId}`,
        {
          balance_amount: balanceAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.response);
      setEditBalanceId(null); // Hide the edit section
      fetchUserBalances(); // Refresh the balance list
    } catch (error) {
      toast.error(error.response.data.response);
      setEditBalanceId(null); // Hide the edit section after failure as well
      fetchUserBalances(); // Refresh the balance list after failure
    }
  };

  if (loading) {
    return <div className="text-center mt-6">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-6">User Balances</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b">Balance ID</th>
            <th className="py-2 px-4 border-b">User ID</th>
            <th className="py-2 px-4 border-b">Balance</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {balances.map((balance) => (
            <tr key={balance.id} className="border-b hover:bg-gray-100">
              <td className="py-2 px-4">{balance.balance_id}</td>
              <td className="py-2 px-4">{balance.user_id}</td>
              <td className="py-2 px-4">{balance.balance}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleEdit(balance)}
                  className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 mr-2"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editBalanceId && (
        <div className="mt-6 p-4 border border-gray-200 rounded">
          <h2 className="text-xl font-bold mb-4">
            Edit User Balance for user ID {userId}
          </h2>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Balance Amount</label>
            <input
              type="text"
              value={balanceAmount}
              onChange={(e) => setBalanceAmount(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <button
            onClick={handleUpdate}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default UpdateUserBalance;
