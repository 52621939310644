import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const MaintenanceRequest = () => {
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMaintenanceRequests();
  }, []);

  const fetchMaintenanceRequests = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/maintenance/maintenancerequest/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMaintenanceRequests(response.data.response);
    } catch (error) {
      toast.error(error.response.data.response);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-6">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-6">
        Maintenance Requests
      </h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b">Request ID</th>
            <th className="py-2 px-4 border-b">User ID</th>
            <th className="py-2 px-4 border-b">Property ID</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">House Number</th>
            <th className="py-2 px-4 border-b">Created At</th>
          </tr>
        </thead>
        <tbody>
          {maintenanceRequests.map((request) => (
            <tr key={request.id} className="border-b hover:bg-gray-100">
              <td className="py-2 px-4">{request.request_id}</td>
              <td className="py-2 px-4">{request.user_id}</td>
              <td className="py-2 px-4">{request.property_id}</td>
              <td className="py-2 px-4">{request.request_content}</td>
              <td className="py-2 px-4">{request.status}</td>
              <td className="py-2 px-4">{request.house_no}</td>
              <td className="py-2 px-4">
                {new Date(request.created_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MaintenanceRequest;
