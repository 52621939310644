import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const Home = () => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    // Redirect to profile screen
    navigate("/admin/profile");
  };

  const handleLogoutClick = () => {
    // Redirect to logout screen
    navigate("/logout");
  };

  const handleUserBalancesClick = () => {
    navigate("/admin/userbalances");
  };

  const handleOwnerBalancesClick = () => {
    navigate("/admin/ownerbalances");
  };

  const handleReservationsClick = () => {
    navigate("/admin/reservations");
  };

  const handleBillClick = () => {
    navigate("/admin/bill");
  };

  const handleWaterBillClick = () => {
    navigate("/admin/waterbill");
  };

  const handleVacatingClick = () => {
    navigate("/admin/vacating");
  };

  const handleVideosClick = () => {
    navigate("/admin/videos");
  };

  const handlePhotosClick = () => {
    navigate("/admin/photos");
  };

  const handlePromocodesClick = () => {
    navigate("/admin/promocodes");
  };

  const handleAllUsersClick = () => {
    navigate("/admin/users");
  };

  const handleBank = () => {
    navigate("/admin/bank");
  };

  const handlePropertiesClick = () => {
    navigate("/admin/propertypage");
  };

  const handleRentalsClick = () => {
    navigate("/admin/rentals");
  };

  const handleMoneyOutClick = () => {
    navigate("/admin/moneyout");
  };

  const handleMoneyInClick = () => {
    navigate("/admin/moneyin");
  };

  const handleDepositClick = () => {
    navigate("/admin/deposit");
  };

  const handleMonthlyFeesClick = () => {
    navigate("/admin/monthlyfees");
  };

  const handlePropertyKYCClick = () => {
    navigate("/admin/propertykyc");
  };

  const handlePropertyHunter = () => {
    navigate("/admin/propertyhunter");
  };

  const handleViewSettlementUser = () => {
    navigate("/admin/viewusersettlement");
  };

  const handleViewSettlementLandlord = () => {
    navigate("/admin/viewlandlordsettlement");
  };

  const handleViewSettlementHunter = () => {
    navigate("/admin/viewpropertyhuntersettlement");
  };
  const handleViewDepositSettlements = () => {
    navigate("/admin/admincompletesettlements");
  };

  const handleViewNotifications = () => {
    navigate("/admin/viewnotifications");
  };
  const handleRecordsView = () => {
    navigate("/admin/recordsview");
  };
  const handleViewAuditLogs = () => {
    navigate("/admin/auditlogs");
  };
  const handleAgencyViewingFee = () => {
    navigate("/admin/agencyviewing");
  };
  const handleMaintenaceRequests = () => {
    navigate("/admin/maintenancerequest");
  };
  const handleWithdrawals = () => {
    navigate("/admin/withdrawals");
  };
  const handleVerifyCodes = () => {
    navigate("/admin/viewverifycodes");
  };
  const handleAdminSettlementsView = () => {
    navigate("/admin/viewadminsettlements");
  };
  const handleServiceCharge = () => {
    navigate("/admin/serviceratecharge");
  };
  const handleWithdrawalLink = () => {
    navigate("/admin/withdrawalshelplandlord");
  };

  const handleAddReservationBills = () => {
    navigate("/admin/addreservationbills");
  };

  const handleCheckWaterUnits = () => {
    navigate("/admin/checkwaterunits");
  };

  const handleUpdateLandlordBalances = () => {
    navigate("/admin/updatelandlordbalance");
  };
  const handleUpdateUserBalances = () => {
    navigate("/admin/updateuserbalances");
  };

  const handleUpdateBills = () => {
    navigate("/admin/updatebills");
  };

  const handleEmailSending = () => {
    navigate("/admin/emailsender");
  };

  const handlePropertyTransactions = () => {
    navigate("/admin/transactionsviewpage");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">My Dashboard</h1>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faUser}
              className="text-xl mr-4 cursor-pointer"
              onClick={handleProfileClick}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="text-xl cursor-pointer"
              onClick={handleLogoutClick}
            />
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-8">
            <button
              onClick={handlePromocodesClick}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Promocodes
            </button>
            <button
              onClick={handleUserBalancesClick}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              User Balances
            </button>
            <button
              onClick={handleOwnerBalancesClick}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Owner Balances
            </button>
            <button
              onClick={handleReservationsClick}
              className="button-style bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Reservations
            </button>
            <button
              onClick={handleBillClick}
              className="button-style bg-gradient-to-r from-purple-400 to-purple-600 hover:from-purple-600 hover:to-purple-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Bill
            </button>
            <button
              onClick={handleWaterBillClick}
              className="button-style bg-gradient-to-r from-pink-400 to-pink-600 hover:from-pink-600 hover:to-pink-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              WaterBill
            </button>
            <button
              onClick={handleMoneyOutClick}
              className="button-style bg-gradient-to-r from-indigo-400 to-indigo-600 hover:from-indigo-600 hover:to-indigo-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              MoneyOut
            </button>
            <button
              onClick={handleMoneyInClick}
              className="button-style bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-600 hover:to-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              MoneyIn
            </button>
            <button
              onClick={handleDepositClick}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Deposit
            </button>
            <button
              onClick={handleMonthlyFeesClick}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              MonthlyFees
            </button>
            <button
              onClick={handleVacatingClick}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Vacating
            </button>
            <button
              onClick={handleVideosClick}
              className="button-style bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Videos
            </button>
            <button
              onClick={handlePhotosClick}
              className="button-style bg-gradient-to-r from-purple-400 to-purple-600 hover:from-purple-600 hover:to-purple-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Photos
            </button>
            <button
              onClick={handlePropertyKYCClick}
              className="button-style bg-gradient-to-r from-pink-400 to-pink-600 hover:from-pink-600 hover:to-pink-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              PropertyKYC
            </button>
            <button
              onClick={handlePropertiesClick}
              className="button-style bg-gradient-to-r from-indigo-400 to-indigo-600 hover:from-indigo-600 hover:to-indigo-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Properties
            </button>
            <button
              onClick={handleServiceCharge}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Service Charge Percentage
            </button>
            <button
              onClick={handleRentalsClick}
              className="button-style bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-600 hover:to-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Rentals
            </button>
            <button
              onClick={handleAllUsersClick}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              View Users
            </button>
            <button
              onClick={handleVerifyCodes}
              className="button-style bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-600 hover:to-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              VerifyCodes
            </button>
            <button
              onClick={handleBank}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Bank
            </button>
            <button
              onClick={handleViewSettlementUser}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              UserSettlementView
            </button>
            <button
              onClick={handleViewSettlementLandlord}
              className="button-style bg-gradient-to-r from-red-400 to-green-600 hover:from-red-600 hover:to-red-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              LandlordSettlementView
            </button>
            <button
              onClick={handleViewSettlementHunter}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              PropertyHunterSettlementView
            </button>
            <button
              onClick={handleAdminSettlementsView}
              className="button-style bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-600 hover:to-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Admin Settlements View
            </button>
            <button
              onClick={handlePropertyHunter}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              PropertyHunter
            </button>
            <button
              onClick={handleViewDepositSettlements}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              ViewDepositSettlementsToComplete
            </button>
            <button
              onClick={handleViewNotifications}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              ViewNotifications
            </button>
            <button
              onClick={handleRecordsView}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              ViewRecords
            </button>
            <button
              onClick={handleViewAuditLogs}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              AuditLogs
            </button>
            <button
              onClick={handleMaintenaceRequests}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Maintenancerequest
            </button>
            <button
              onClick={handleAgencyViewingFee}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              ViewAgencyViewingFee
            </button>
            <button
              onClick={handleWithdrawals}
              className="button-style bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-600 hover:to-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Withdrawals
            </button>
            <button
              onClick={handleWithdrawalLink}
              className="button-style bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Send withdrawal Link for Landlord.
            </button>
            <button
              onClick={handleAddReservationBills}
              className="button-style bg-gradient-to-r from-blue-400 to-gray-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Add bills for property
            </button>
            <button
              onClick={handleCheckWaterUnits}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Check water units for property
            </button>

            <button
              onClick={handleUpdateLandlordBalances}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Update Property Balance
            </button>

            <button
              onClick={handleUpdateUserBalances}
              className="button-style bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Update User Balances
            </button>

            <button
              onClick={handleUpdateBills}
              className="button-style bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-600 hover:to-yellow-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Update Bills
            </button>

            <button
              onClick={handleEmailSending}
              className="button-style bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Send Emails
            </button>

            <button
              onClick={handlePropertyTransactions}
              className="button-style bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              PropertyTransactions
            </button>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

const Button = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    {children}
  </button>
);

export default Home;
