import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";

const RentalsPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [rentals, setRentals] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rentalIdToUpdate, setRentalIdToUpdate] = useState("");
  const [rentPriceInput, setRentPriceInput] = useState("");

  useEffect(() => {
    if (propertyId) {
      fetchRentals();
    }
  }, [propertyId]);

  // Helper function to get token
  const getToken = () => localStorage.getItem("token");

  // Fetch rentals for the selected property
  const fetchRentals = async () => {
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/propertyall/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setRentals(response.data.response);
    } catch (error) {
      handleError(error, "Error fetching rentals");
    }
  };

  // Handle rental deletion
  const handleDelete = async (rentalId) => {
    try {
      const response = await axios.delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/delete/${rentalId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      toast.success(response.data.response);
      fetchRentals();
    } catch (error) {
      handleError(error, "Error deleting rental");
    }
  };

  // Handle rental price update
  const handleUpdate = async () => {
    try {
      const response = await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/price/${rentalIdToUpdate}`,
        { rent_price: rentPriceInput },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      toast.success(response.data.response);
      setModalOpen(false);
      fetchRentals();
    } catch (error) {
      handleError(error, "Error updating rental price");
    }
  };

  // Open modal to update rental price
  const openModalToUpdate = (rentalId) => {
    setRentalIdToUpdate(rentalId);
    setModalOpen(true);
  };

  // Handle errors with a toast notification
  const handleError = (error, defaultMessage) => {
    console.error(defaultMessage, error);
    const errorMessage = error.response?.data?.response || defaultMessage;
    toast.error(errorMessage);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <input
          type="text"
          placeholder="Enter Property ID"
          value={propertyId}
          onChange={(e) => setPropertyId(e.target.value)}
          className="border border-gray-300 rounded-md py-2 px-4 mb-4"
        />
        <button
          onClick={fetchRentals}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Fetch Rentals
        </button>

        <table className="w-full mt-4">
          <thead>
            <tr>
              <th className="px-4 py-2">Rental ID</th>
              <th className="px-4 py-2">Rent Price</th>
              <th className="px-4 py-2">House Number</th>
              <th className="px-4 py-2">House Type</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rentals.map((rental) => (
              <tr key={rental.id}>
                <td className="border px-4 py-2">{rental.rental_id}</td>
                <td className="border px-4 py-2">{rental.rent_price}</td>
                <td className="border px-4 py-2">{rental.house_number}</td>
                <td className="border px-4 py-2">{rental.house_type}</td>
                <td className="border px-4 py-2">{rental.status}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleDelete(rental.rental_id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => openModalToUpdate(rental.rental_id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for updating rent price */}
        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md">
              <input
                type="number"
                value={rentPriceInput}
                onChange={(e) => setRentPriceInput(e.target.value)}
                className="border border-gray-300 rounded-md py-2 px-4 mb-4"
              />
              <button
                onClick={handleUpdate}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Update Rent Price
              </button>
            </div>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default RentalsPage;
