import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";

const DepositPage = () => {
  const [deposits, setDeposits] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [updateAmount, setUpdateAmount] = useState("");

  useEffect(() => {
    fetchDeposits();
  }, []);

  const fetchDeposits = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/money/viewDeposit", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDeposits(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching deposits:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleUpdateModalOpen = (deposit) => {
    setSelectedDeposit(deposit);
    setUpdateAmount(deposit.amount);
    setModalOpen(true);
  };

  const handleUpdateDeposit = () => {
    const token = localStorage.getItem("token");
    axios
      .patch(
        "https://backend.inyumba.co.ke/iNyumba/money/updatedeposit",
        {
          userid: selectedDeposit.user_id,
          amount: updateAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        setModalOpen(false);
        fetchDeposits();
      })
      .catch((error) => {
        console.error("Error updating deposit:", error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Deposits</h1>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">User ID</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {deposits.map((deposit) => (
              <tr key={deposit.userid}>
                <td className="border px-4 py-2">{deposit.user_id}</td>
                <td className="border px-4 py-2">{deposit.deposit}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleUpdateModalOpen(deposit)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-4">Update Deposit</h2>
              <input
                type="text"
                value={updateAmount}
                onChange={(e) => setUpdateAmount(e.target.value)}
                className="border border-gray-300 rounded-md py-2 px-4 mb-4"
                placeholder="Amount"
              />
              <button
                onClick={handleUpdateDeposit}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Update Deposit
              </button>
            </div>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default DepositPage;
